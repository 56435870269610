<template>
  <div class="workorder-page">
    <Navbar />
    <Breadcrumbs></Breadcrumbs>
    <WorkLocation/>
    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import WorkLocation from '@/components/work-locations/WorkLocation.vue'
import Footer from '@/components/Footer.vue';

export default {
  components: {
    Navbar,
    WorkLocation,
    Footer
  }
}
</script>
