<template>
    <div class="container">

        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <EditWLGroup />
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div>
            </div>
        </div>
        </div>

        <ModalWorkHistory inputId="modalHistory"/>


        <div class="title-header">
            <div class="row px-3" v-if="selectedWorkLocation">
                <div class="col-sm-6">
                <h2>
                    <span v-if="workOrder" class="badge bg-primary">{{workOrder.code}}</span>
                    <span class="badge bg-primary">{{getLocationFullindex(selectedWorkLocation)}}</span>

                    <template>
                        <span class="ms-1" v-for="(name, index) in fullNameList(selectedWorkLocation)" :key="index">
                            <span :class="{'badge':true, 'bg-secondary':true }">
                                {{name}}
                            </span>
                            ❯
                        </span>
                    </template>
                    <span class="ms-1 badge bg-primary">{{selectedWorkLocation.name}}</span>
                </h2>
                </div>

                <div class="col-sm-6">
                <div class="row">
                    <div class="col-sm-6">
                    <div class="d-grid gap-2">
                        <!-- <button type="button" class="btn-header btn-block btn-primary btn-lg"><i class="fas fa-file-excel fa-lg me-1" /> Export Excel</button> -->
                    </div>
                    </div>
                    <div class="col-sm-6">
                    <div class="d-grid gap-2">
                        <!-- TODO -->
                        <!-- <button disabled type="button" class="btn-header btn-block btn-primary btn-lg" v-on:click="editWLGroup"><i class="fas fa-edit fa-lg me-1" /> Izmeni posao</button> -->
                        <!-- <button disabled type="button" class="btn-header btn-block btn-primary btn-lg" data-bs-toggle="modal" data-bs-target="#exampleModal" v-on:click="editWLGroup"><i class="fas fa-edit fa-lg me-1" /> Izmeni posao</button> -->
                    </div>
                    </div>
                </div>

                </div>


            </div>

        </div>
        
        
        <VueNestable
            v-model="workLocationPositions"
            class-prop="class"
            childrenProp="children"
            class="mb-5"
            >

            <template slot-scope="{ item }">
            <!-- Regular view -->        
            <div class="clearfix" :class="[getClassForPosition(item), item.position_unit_id?'workable':'not-workable']">

                <div class="item-text clearfix d-inline-block">
                <a href="#" v-if="item.workable" data-bs-toggle="modal" data-bs-target="#modalHistory"><span>{{item.fullIndex}} {{ item.name }}</span></a>
                
                
                <span v-if="item.position_unit_id"><span class="ms-2 badge bg-primary">{{item.fullIndex}} {{ getWorkablePositionName(item) }}</span> {{ item.name }}</span>
                <span v-else :class="'ms-2 badge ' + workStatusClass(item.status)">{{item.fullIndex}} {{item.name }}</span>

                </div>

                <div v-if="item.position_unit_id" class="item-button-set d-inline float-end">
                    <template v-if="item.work">
                        <!-- <span class="worker">
                            <router-link :to="{ name: 'Workers'}">Marko Markovic</router-link>
                        </span>                
                        <span class="date">12.5.2020.</span> -->
                        
                        <span v-if="item.work" class="ms-1 badge bg-primary" :class="workClass(item.work)">{{workLabel(item.work)}}</span>

                        <router-link v-if="user.role !== 'WORKER' && workStatusStage(item.work) == 1" type="button" class="btn btn-primary ms-2"
                            :to="{ name: 'JobAssignment', query:{'workOrderId': workOrderID, 'workPositionId': item.id, 'workLocationId': workLocationID}}"
                        ><i class="fas fa-hard-hat"></i></router-link>
                    </template>
                   
                    <button type="button" class="btn btn-info" v-on:click="loadWorkHistory(item.id)" data-bs-toggle="modal" data-bs-target="#modalHistory"><i class="fas fa-info-circle"></i></button>
                </div>

            </div>

            </template>


        </VueNestable>
    </div>
</template>

<script>
require('@/assets/vue-nestable.css')
require('@/assets/tree-view.css')
import UtilService from '@/service/UtilService.js';
import EditWLGroup from '@/components/EditWLGroup.vue';
import ModalWorkHistory from '@/components/modals/ModalWorkHistory.vue';
import WorkLocationService from '@/service/WorkLocationService.js';
import {mapState} from 'vuex'

export default {
    components: {
        EditWLGroup,
        ModalWorkHistory
    },

    data () {
        return {

            workLocationPositionsCopy: [],
            works: [],
            worksMap: undefined,
            workLocationPositions: [],

        }
    },  
    computed: {
        ...mapState([
        'position_units',
        'selectedWorkLocation',
        'workOrder',
        'user',
        ]),
        

        workOrderID() {
        return parseInt(this.$route.params.woId)
        },

        workLocationID() {
        return parseInt(this.$route.params.wlId)
        },

        workLocationsData() {
            return this.workLocationPositions;
        },

    },
    methods: {
        workLabel : UtilService.workLabel,
        workClass : UtilService.workClass,
        workStatusLabel : UtilService.workStatusLabel,
        workStatusClass : UtilService.workStatusClass,
        workStatusStage : UtilService.workStatusStage,
        fullNameList : UtilService.fullNameList,
        getLocationFullindex : UtilService.getLocationFullindex,

        getWorkStatusLabel (work) {
            return this.workStatusLabel(work.workStatus);
        },
        getWorkStatusClass (work) {
            return this.workStatusClass(work.workStatus);
        },


        async loadLocationPositions() {
            const response = await WorkLocationService.getTreeWorkLocationPositions(this.workLocationID);
            this.workLocationPositionsCopy = [response.data];

            this.$store.dispatch('loadPositionUnitsIfUndefined').then(() => {
                UtilService.recalculatePositionFullIndex(this.workLocationPositionsCopy)
            })

            await this.loadWorks();

            this.worksMap = this.worksToLocationMap();

            // this.workLocationPositionsCopy = this.workLocationPositions;
            this.mergePositionsAndWorks(this.workLocationPositionsCopy);
            this.workLocationPositions = this.workLocationPositionsCopy;

        },

        worksToLocationMap() {
            let locationWorks = new Map();

            this.works.forEach(work => {
                if (!locationWorks.has(work.job_assignment.work_position_id)){
                    locationWorks.set(work.job_assignment.work_position_id, []);
                }


                locationWorks.get(work.job_assignment.work_position_id).push(work);
                
            });

            return locationWorks;
        },



        mergePositionsAndWorks(obj) {
            let finished_all = true;

            for(let i = 0 ; i < obj.length ; i++) {


                if (obj[i].children.length > 0){
                    // obj[i].class = 'container'

                    let result = this.mergePositionsAndWorks(obj[i].children);
                    if (!result) finished_all = false;
                    else obj[i].finished = true;
                } else {
                    obj[i].work = this.findLastWork(obj[i].id);
                    if (obj[i]?.work?.workStatus != "APPROVED") finished_all = false;

                }

                
            }
            


            // if (finished_all) obj.finished = true;
            return finished_all;
        },

        getWorkStatusStage(workStatus) {
            if (workStatus == "NOT_SUBMITTED" || workStatus == "REFUSED") return 0;
            if (workStatus == "IN_PROGRESS" || workStatus == "DONE" || workStatus == "NOT_DONE") return 1;
            if (workStatus == "APPROVED") return 2;
            return 0;
        },

        findLastWork(workPositionId) {
            if (!this.worksMap.has(workPositionId))
                return null;

            let works = this.worksMap.get(workPositionId);
            if (works.length == 0) return null
            if (works.length == 1) return works[0]


            let result = works[0];
            // TODO UtilService replace
            let result_stage = UtilService.workStatusStage(result);
            

            works.forEach(work => {
                if (result_stage == 3) return result;
                if (UtilService.workStatusStage(work) > result_stage){
                    result = work;
                    result_stage = UtilService.workStatusStage(work);
                }


            });
            // works.sort(function(a,b){
            //     return Date.parse(a.) < Date.parse(b);
            // });

            return result;

        },

        getClassForPosition(item) {
            if (item.finished) return 'bg-success';

            if (!item.work) return '';
            
            if (item.work.workStatus == 'APPROVED') return 'work-bg-approved';
            if (item.work.workStatus == 'DONE') return 'work-bg-checked';
            if (item.work.workStatus == 'NOT_DONE') return 'work-bg-not-started';
            if (item.work.workStatus == 'REJECTED') return 'work-bg-rejected';
            
        },

        async loadWorks() {
            const response = await WorkLocationService.getWorksInWorkLocation({workLocationId: this.workLocationID});
            this.works = response.data;    

        },

        loadWorkHistory(workPositionId) {

            this.$store.dispatch('loadLocationPositionWorks', {workLocationId: this.workLocationID, workPositionId: workPositionId});
            this.$store.dispatch('loadNormative', {typeId: this.selectedWorkLocation.type_id, workPositionId: workPositionId});
            this.$store.dispatch('loadWorkPosition', {workPositionId: workPositionId});
            this.$store.dispatch('loadWorkLocation', {workLocationId: this.workLocationID});


        },
        

        getWorkablePositionName(item) {
            if (!item.position_unit) return item.name;

            var name = item.position_unit.name
            if (item.name) name += ' (' + item.name + ')';
            return name;
        },

    },
    created() {
        this.loadLocationPositions();
        this.$store.dispatch('loadWorkLocation', {workLocationId: this.workLocationID, prepareParents: true});
        this.$store.dispatch('getWorkOrder', {id: this.workOrderID});

    }
}
</script>

<style>
    .container {
        min-height: 74vh;
    }

    .item-button-set span {
    margin-left: 10px;
    }

    .item-button-set button {
    margin-left: 10px;
    }

    .edit-commands {
        position: sticky;
        top: 0;
        background: white;
        z-index: 10;
        max-height: 50vh;
        /* overflow-x: hidden; */
        overflow-y: auto;
        box-shadow: #00000026 0 6px 20px;
        margin: 0 -50px;
        padding: 0 50px;
        resize: vertical;
        
    }


    .title-header{
        padding: 1em 0;
        background: #299be4;
        color: #fff;
        margin: 10px 0px 0;
    }
    .title-header h2{
        margin: 5px 0 0;
        font-size: 1.8em;
    }
    .title-header .btn-header {
        color: #566787;
        float: right;
        font-size: 13px;
        background: #fff;
        border: none;
        min-width: 50px;
        border-radius: 2px;
        border: none;
        outline: none !important;
        /* margin-left: 10px; */
    }
    .title-header .btn-header:hover, .title-header .btn-header:focus {
        color: #566787;
        background: #f2f2f2;
    }

    .title-header .btn-header .btn-title {
        margin-top: 20px;
    }

    .nestable .work-bg-approved {
        background: #38f157
    }

    .nestable .work-bg-checked {
        background: #cfcfcf
    }

    .nestable .work-bg-not-started {
        background: #cfcfcf
    }

    .nestable .work-bg-rejected {
        background: #ff5b5b
    }

  </style>